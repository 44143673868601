import moment from 'moment';
import React, { Component } from 'react';
import MaskedInput from 'react-text-mask';
import composeRefs from '@seznam/compose-react-refs';

import warehouses from '../data/warehouses';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Button from '../components/Button';
import { cleanTelStr, Input, Select, TelInput } from '../components/FormControls';
import SuccessPage from '../components/SuccessPage';

import styles from './retrieve.module.scss';

export default class RetrievePage extends Component {
  state = {
    name: '',
    tel: '',
    warehouseId: 1,
    unitNo: '',
    date: moment().format('YYYY/MM/DD'),
    time: '14:00',

    isSaving: false,
    isSuccess: false
  };

  submit = async () => {};

  onChangeForm = ({ target }) => {
    const { name } = target;
    let { value } = target;

    if (name === 'warehouseId') {
      value = parseInt(value, 10);
    }

    this.setState({ [name]: value });
  };

  shouldAllowSubmit() {
    const { name, unitNo, date, time } = this.state;
    const tel = cleanTelStr(this.state.tel);
    return !!(
      name.trim() &&
      tel.length === 8 &&
      unitNo.trim() &&
      moment(date, 'YYYY/MM/DD', true).isValid() &&
      moment(time, 'HH:mm', true).isValid()
    );
  }

  render() {
    const { name, tel, warehouseId, unitNo, date, time, isSaving, isSuccess } = this.state;

    if (isSuccess) {
      return (
        <SuccessPage
          metaTitle="成功預約"
          title="您已成功預約提取"
          content={<p>我們將盡快為您準備您的流動倉。我們的服務主任將於24小時內來電聯絡您。</p>}
        />
      );
    }

    return (
      <Layout location={this.props.location} navAlwaysFixed>
        <SEO title="預約提取" />
        <section className={styles.content}>
          <div className={styles.wrapper}>
            <h1>預約提取</h1>
            <p>
              提取物件前先預約，讓我們為您提供最合適的預約。
              請輸入以下簡單資料，我們的服務主任將於24小時內來電了解您的需要，並為您推薦最合適的儲存方案。
              您亦可立即致電 2111 3113 聯絡我們。
            </p>
            <div className={styles.form}>
              <div className={styles.row}>
                <Input
                  name="name"
                  label="姓名"
                  placeholder=""
                  value={name}
                  maxLength={50}
                  disabled={isSaving}
                  onChange={this.onChangeForm}
                />
                <TelInput name="tel" label="電話" value={tel} disabled={isSaving} onChange={this.onChangeForm} />
              </div>
              <div className={styles.row}>
                <Select
                  name="warehouseId"
                  label="倉分店"
                  value={warehouseId}
                  disabled={isSaving}
                  onChange={this.onChangeForm}
                >
                  {warehouses.map(warehouse => (
                    <option key={warehouse.id} value={warehouse.id}>
                      {warehouse.name}
                    </option>
                  ))}
                </Select>
                <Input
                  name="unitNo"
                  label="倉庫編號"
                  placeholder=""
                  value={unitNo}
                  maxLength={50}
                  disabled={isSaving}
                  onChange={this.onChangeForm}
                />
              </div>
              <div className={styles.row}>
                <MaskedInput
                  mask={[/[2]/, /[0-9]/, /[0-9]/, /[0-9]/, '/', /[0-2]/, /[0-9]/, '/', /[0-3]/, /[0-9]/]}
                  name="date"
                  placeholder="年年年年/月月/日日"
                  value={date}
                  disabled={isSaving}
                  onChange={this.onChangeForm}
                  render={(ref, inputProps) => <Input ref={composeRefs(ref)} {...inputProps} label="提取日期" />}
                />
                <MaskedInput
                  mask={[/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/]}
                  name="time"
                  placeholder="HH:MM"
                  value={time}
                  disabled={isSaving}
                  onChange={this.onChangeForm}
                  render={(ref, inputProps) => <Input ref={ref} {...inputProps} label="提取時間" />}
                />
              </div>
              <div className={styles.button}>
                <Button tabIndex={0} disabled={!this.shouldAllowSubmit() || isSaving} onClick={this.submit}>
                  預約提取
                </Button>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
